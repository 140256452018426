import React from "react";
import Layout from "../components/layout/layout";

export default function Blog() {
  return (
    <Layout>
      <h1>The Custard Running Club Blog!</h1>
    </Layout>
  );
}
